import { useEffect } from 'react'

export const useAnimateContentHeight = (
  container: HTMLDivElement | null,
  content: HTMLDivElement | null,
  noHeightShrink: boolean = false,
  mobile: boolean | null,
  forceMaxHeight?: boolean
) => {
  useEffect(() => {
    if (!container || !content || !mobile) {
      return
    }

    const adjustHeight = () => {
      if (!container || !content || forceMaxHeight) {
        return
      }

      const targetHeight = content.offsetHeight

      if (noHeightShrink && targetHeight < container.offsetHeight) {
        /**
         * Например при поиске телефонного кода страны, мы фильтруем список.
         * И не даем контейнеру уменьшаться по высоте, чтобы он не уезжал вниз.
         */
        return
      }

      /**
       * Не самое лучшее решение, т.к. список внутри bottom sheet может быть очень длинным.
       * Например Соглашение или Политика конфиденциальности.
       * От высоты блока, в котором находится контент, зависит скорость изменения высоты.
       * Например, чтобы растянуть по высоте с 10px до 100px за 0.5 секунд, не нужна высокая скорость.
       * Совсем другой случай, если нужно растянуть с 10px до 10000px.
       * @TODO придумать решение получше в будущем.
       */
      container.offsetHeight // Trigger reflow
      container.style.transition = 'height 0.5s ease'
      container.style.height = targetHeight + 'px'
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        switch (mutation.type) {
          case 'childList':
          case 'attributes':
            adjustHeight()
            break
        }
      })
    })

    observer.observe(content, config)

    adjustHeight()

    return () => {
      observer.disconnect()
    }
  }, [container, content, forceMaxHeight, mobile, noHeightShrink])
}

const config = {
  attributes: true,
  childList: true,
  subtree: true,
  characterData: true,
}
