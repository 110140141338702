import { useCallback, useEffect, useRef } from 'react'

import { useResizeObserver } from 'hooks/useResizeObserver'

import { ScrollbarRefProps } from '../Scrollbar.types'

export const useScrollbar = <
  // TS кидает ошибку, если T и K разных типов. Например HTMLFormElement и HTMLDivElement. Решается разделением.
  T extends HTMLElement,
  K extends HTMLElement
>(
  /** Контейнер, внутри которого скроллится контент */
  scrollableNode: T | null,
  /** Контейнер, который "крутится" внутри родителя (scrollableNode). */
  innerNode: K | null
) => {
  const scrollbarRef = useRef<ScrollbarRefProps>(null)

  const handleResize: ResizeObserverCallback = useCallback(() => {
    if (scrollbarRef.current && scrollableNode) {
      scrollbarRef.current.update(
        scrollableNode.scrollHeight,
        scrollableNode.clientHeight
      )
    }
  }, [scrollableNode])

  useResizeObserver<T>({ current: scrollableNode }, handleResize)
  useResizeObserver<K>({ current: innerNode }, handleResize)

  const handleScroll = useCallback(
    (event) => {
      const _event = event.currentTarget
      window.requestAnimationFrame(() => {
        scrollbarRef.current?.scroll(_event.scrollTop)
      })
    },
    [scrollbarRef]
  )

  useEffect(() => {
    if (scrollableNode) {
      scrollableNode.addEventListener('scroll', handleScroll)
    }

    return () => {
      scrollableNode?.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, scrollableNode])

  return {
    /** Навесить на компонент Scrollbar */
    scrollbarRef,
  }
}
