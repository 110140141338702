import React, { FC, PropsWithChildren, useEffect, useState } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { breakpoints } from '../shared/breakpoints'

export const BottomSheetFooter: FC<
  { contentNode: HTMLDivElement | null } & PropsWithChildren
> = ({ contentNode, children }) => {
  const { mobile } = useShallowEqualSelector(({ systemView: { mobile } }) => ({
    mobile,
  }))

  const [showBorder, setShowBorder] = useState(false)

  useEffect(() => {
    if (!contentNode || !mobile) {
      return
    }

    const calculateShowBorder = () =>
      contentNode.scrollTop + contentNode.clientHeight <
      contentNode.scrollHeight

    // Чтобы отработало первый раз.
    setShowBorder(calculateShowBorder())

    const handleScroll = () => {
      setShowBorder(calculateShowBorder())
    }

    contentNode.addEventListener('scroll', handleScroll)

    return () => {
      contentNode.removeEventListener('scroll', handleScroll)
    }
  }, [contentNode, mobile])

  return (
    <Footer className={cx(showBorder && footerBorderCss)}>{children}</Footer>
  )
}

const footerBorderCss = css`
  box-shadow: 0 1px 0 0 var(--border-muted) inset;
`

const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  width: calc(100% + (var(--spacing-24px) * 2));
  margin-left: calc(-1 * var(--spacing-24px));
  margin-right: calc(-1 * var(--spacing-24px));
  padding: var(--spacing-16px);
  background: var(--gradient-bottom-vertical);

  @media (min-width: ${breakpoints.mobile}px) {
    width: fit-content;
    margin: 0 auto;
    background: none;
  }
`
